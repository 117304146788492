frappe.provide("custom.views");

custom.views.ListView = class extends frappe.views.ListView{
    //Overided for Removing the Like Icon
    after_render() {
        super.after_render();
        this.list_view_settings.disable_comment_count = 1;
		$("use.like-icon").hide();
	}
    //Overided for List Settings
	get_menu_items() {
		const doctype = this.doctype;
		const items = [];

		if (frappe.model.can_import(doctype, null, this.meta)) {
			items.push({
				label: __("Import", null, "Button in list view menu"),
				action: () =>
					frappe.set_route("list", "data-import", {
						reference_doctype: doctype,
					}),
				standard: true,
			});
		}

		if (frappe.user_roles.includes("System Manager")) {
			items.push({
				label: __("User Permissions", null, "Button in list view menu"),
				action: () =>
					frappe.set_route("list", "user-permission", {
						allow: doctype,
					}),
				standard: true,
			});
		}

		if (frappe.user_roles.includes("System Manager")) {
			items.push({
				label: __("Role Permissions Manager", null, "Button in list view menu"),
				action: () =>
					frappe.set_route("permission-manager", {
						doctype,
					}),
				standard: true,
			});
		}

		if (
			frappe.model.can_create("Custom Field") &&
			frappe.model.can_create("Property Setter")
		) {
			items.push({
				label: __("Customize", null, "Button in list view menu"),
				action: () => {
					if (!this.meta) return;
					if (this.meta.custom) {
						frappe.set_route("form", "doctype", doctype);
					} else if (!this.meta.custom) {
						frappe.set_route("form", "customize-form", {
							doc_type: doctype,
						});
					}
				},
				standard: true,
				shortcut: "Ctrl+J",
			});
		}

		items.push({
			label: __("Toggle Sidebar", null, "Button in list view menu"),
			action: () => this.toggle_side_bar(),
			condition: () => !this.hide_sidebar,
			standard: true,
			shortcut: "Ctrl+K",
		});

		if (frappe.user.has_role("System Manager") && frappe.boot.developer_mode === 1) {
			// edit doctype
			items.push({
				label: __("Edit DocType", null, "Button in list view menu"),
				action: () => frappe.set_route("form", "doctype", doctype),
				standard: true,
			});
		}

        if (frappe.user.has_role(["System Manager", "List Settings"])) {
            if (this.get_view_settings) {
                items.push(this.get_view_settings());
            }
        }
		items.push({
			label: __("<b>Mark As Read</b>", null, "Button in list view menu"),
			action: () => this.update_as_mark_read(),
			standard: true,
		});

		return items;
	}
	update_as_mark_read = () => {
		const docnames = this.get_checked_items(true);
		if (docnames.length > 0) {
			frappe.call({
				method: "ntpt_erpnext_app.api.mark_as_read",
				args: {
					doctype: this.doctype,
					docnames: JSON.stringify(docnames)
				},
				callback: (r) => {
					// Arrow function ensures `this` context is preserved
					this.clear_checked_items();
					this.refresh();
				},
			});
		}
	};
	

}

frappe.views.ListView = custom.views.ListView;