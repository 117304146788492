frappe.provide("erpnext.utils");

const SALES_DOCTYPES = ["Quotation", "Sales Order", "Delivery Note", "Sales Invoice"];
const PURCHASE_DOCTYPES = ["Supplier Quotation", "Purchase Order", "Purchase Receipt", "Purchase Invoice"];

erpnext.utils.get_party_details = function (frm, method, args, callback) {
	if (!method) {
		method = "erpnext.accounts.party.get_party_details";
	}

	if (!args) {
		if (
			(frm.doctype != "Purchase Order" && frm.doc.customer) ||
			(frm.doc.party_name && ["Quotation", "Opportunity"].includes(frm.doc.doctype))
		) {
			let party_type = "Customer";
			if (frm.doc.quotation_to && ["Lead", "CRM Lead", "Prospect"].includes(frm.doc.quotation_to)) {
				party_type = frm.doc.quotation_to;
			}

			args = {
				party: frm.doc.customer || frm.doc.party_name,
				party_type: party_type,
				price_list: frm.doc.selling_price_list,
			};
		} else if (frm.doc.supplier) {
			args = {
				party: frm.doc.supplier,
				party_type: "Supplier",
				bill_date: frm.doc.bill_date,
				price_list: frm.doc.buying_price_list,
			};
		}

		if (!args) {
			if (in_list(SALES_DOCTYPES, frm.doc.doctype)) {
				args = {
					party: frm.doc.customer || frm.doc.party_name,
					party_type: "Customer",
				};
			}

			if (in_list(PURCHASE_DOCTYPES, frm.doc.doctype)) {
				args = {
					party: frm.doc.supplier,
					party_type: "Supplier",
				};
			}
		}

		if (!args || !args.party) return;

		args.posting_date = frm.doc.posting_date || frm.doc.transaction_date;
		args.fetch_payment_terms_template = cint(!frm.doc.ignore_default_payment_terms_template);
	}

	if (in_list(SALES_DOCTYPES, frm.doc.doctype)) {
		if (!args.company_address && frm.doc.company_address) {
			args.company_address = frm.doc.company_address;
		}
	}

	if (in_list(PURCHASE_DOCTYPES, frm.doc.doctype)) {
		if (!args.company_address && frm.doc.billing_address) {
			args.company_address = frm.doc.billing_address;
		}

		if (!args.shipping_address && frm.doc.shipping_address) {
			args.shipping_address = frm.doc.shipping_address;
		}
	}

	if (frappe.meta.get_docfield(frm.doc.doctype, "taxes")) {
		if (
			!erpnext.utils.validate_mandatory(
				frm,
				"Posting / Transaction Date",
				args.posting_date,
				args.party_type == "Customer" ? "customer" : "supplier"
			)
		)
			return;
	}

	if (
		!erpnext.utils.validate_mandatory(
			frm,
			"Company",
			frm.doc.company,
			args.party_type == "Customer" ? "customer" : "supplier"
		)
	) {
		return;
	}

	args.currency = frm.doc.currency;
	args.company = frm.doc.company;
	args.doctype = frm.doc.doctype;
	frappe.call({
		method: method,
		args: args,
		callback: function (r) {
			if (r.message) {
				frm.supplier_tds = r.message.supplier_tds;
				frm.updating_party_details = true;
				frappe.run_serially([
					() => frm.set_value(r.message),
					() => {
						frm.updating_party_details = false;
						if (callback) callback();
						frm.refresh();
						erpnext.utils.add_item(frm);
					},
				]);
			}
		},
	});
};
